import Swiper from "swiper";
import type {SwiperOptions} from 'swiper/types/swiper-options';
import {updateFeatureSellerSlide} from "@/modules/swiper/helpers";
import {optionHeroAutoPlayFadeEffect} from "@/modules/swiper/swiperOptions";
import {Autoplay, Navigation, Pagination} from 'swiper/modules';


/**
 * Location popular search in home page
 * Horizontal slide
 * */
export const swiperLocation = () => {
  new Swiper(".swiper-popular" as any, {
    direction: 'horizontal',
    spaceBetween: 12,
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    freeMode: true,
    loop: true,
    modules: [Navigation],
    navigation: {
      nextEl: ".card-swiper-next",
      prevEl: ".card-swiper-prev",
    }
  } as SwiperOptions);
}


/**
 * Hero in home page
 * Auto play, Effect fade
 * */
export const swiperHeroHome = () => {
  new Swiper(
    "#swiper-hero-home" as any,
    optionHeroAutoPlayFadeEffect()
  );
  // remove hidden hero after swiper init
  document.getElementById('swiper-hero-wrapper')
    ?.classList.remove('hidden');
}

/**
 * Feature sellers in home page
 * */
export const featureSellers = () => {
  if (document.getElementById('swiper-feature-sellers')) {
    let swiperSeller = new Swiper("#swiper-feature-sellers" as any, {
      modules: [Navigation, Autoplay],
      loop: true,
      lazy: true,
      slidesPerView: 3,
      loopPreventsSliding: false,
      spaceBetween: 15,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        reverseDirection: true
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 3,
        }
      },
      navigation: {
        nextEl: ".swiper-sellers-next",
        prevEl: ".swiper-sellers-prev",
      },
    } as SwiperOptions);
    updateFeatureSellerSlide(swiperSeller);
  }

}

/**
 * Review slide in property page
 * */
export const swiperReviewCard = (elementName: string = '.swiper-review-card') => {
  new Swiper(elementName as any, {
    modules: [Navigation],
    simulateTouch: true,
    loop: true,
    slidesPerView: 4,
    spaceBetween: 0,
    preventClicks: true,
    navigation: {
      nextEl: ".review-swiper-next",
      prevEl: ".review-swiper-prev",
    },
  } as SwiperOptions);
}

/**
 * Review user list slide in property page
 * */
export const swiperReviewUserList = (
  elementName: string = '.swiper-review-user-list'
) => {
  new Swiper(elementName as any, {
    modules: [Navigation],
    loop: true,
    slidesPerView: 3,
    spaceBetween: 5,
    navigation: {
      nextEl: ".user-swiper-next",
      prevEl: ".user-swiper-prev",
    },
    preventClicks: true,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 4,
      },
      878: {
        slidesPerView: 3,
        spaceBetween: 4,
      },
      1024: {
        slidesPerView: 2,
      },
      1298: {
        slidesPerView: 3,
      },
      1700: {
        slidesPerView: 3,
      }
    },
 /*   on: {
      slideChangeTransitionEnd: function (swiper) {
        let index = swiper.activeIndex;  // get current slide index
        // get slidesPerView
        let slidesPerView = swiper.params.slidesPerView as number;
        // get last slide index of current view
        let lastSlideIndex = index + slidesPerView;

        let total = swiper.slides.length;  // total slides
        let prevButton = document.querySelector('.user-swiper-prev') as HTMLDivElement;  // prev button
        let nextButton = document.querySelector('.user-swiper-next') as HTMLDivElement;  // next button
        //console.log(index, total, lastSlideIndex)
        if (index === 0) {
          prevButton.classList.add('hidden');
        } else {
          prevButton.classList.remove('hidden');
        }

        if (lastSlideIndex === total) {
          nextButton.classList.add('hidden');
        } else {
          nextButton.classList.remove('hidden');
        }

      }
    }*/
  } as SwiperOptions);
}

/**
 * Review slide in property page
 * */
export const swiperReviewFullscreen = (
  elementName: string = '.swiper-review-user-image-fullscreen',
  index: number = 0
) => {
  new Swiper(elementName as any, {
    modules: [Navigation],
    simulateTouch: true,
    loop: true,
    slidesPerView: 1,
    preventClicks: true,
    navigation: {
      nextEl: ".review-user-image-next",
      prevEl: ".review-user-image-prev",
    },
    initialSlide: index,
  } as SwiperOptions);
}

export const swiperSellPage = () => {
  new Swiper(".swiper-sell-page", {
    slidesPerView: 1,
    spaceBetween: 5,
    modules: [Navigation, Pagination],
    loop: true,
    navigation: {
      nextEl: '.swiper-sell-next',
      prevEl: '.swiper-sell-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
}
export const swiperTestimonials = () => {
  new Swiper(".swiper-testimonials", {
    slidesPerView: 1,
    spaceBetween: 5,
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
    },
    modules: [Navigation, Pagination, Autoplay],
    loop: true,
    navigation: {
      nextEl: '.swiper-testimonials-next',
      prevEl: '.swiper-testimonials-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 4,
      },
      1024: {
        slidesPerView: 3,
      }
    }
  });
}
